import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Loader from "./Loader";
import base from "../api/base";

function VINNumber() {
  const [input, setInput] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInputValue(input);

    setInput("");

    if (input.length === 0) {
      setOutput("");
    } else if (input.length !== 17) {
      let outputContent = (
        <p className="vin__number__warning">
          Die VIN besteht aus 17 Zeichen. Ihre Eingabe hat{" "}
          <span>{input.length}</span> Zeichen. Bitte überprüfen Sie Ihre
          Eingabe.
        </p>
      );

      setOutput(outputContent);
    } else {
      setLoading(true);

      base("VIN")
        .select({ filterByFormula: `{VIN} = "${input}"` })
        .firstPage(function (err, records) {
          let existsVinNumber;

          if (err) {
            existsVinNumber = false;
          }

          if (records && records.length > 0) {
            existsVinNumber = true;
          } else {
            existsVinNumber = false;
          }

          setLoading(false);

          let outputContent = existsVinNumber ? (
            <p className="vin__number__found">
              vRB für <span>{input}</span> wurde bezahlt.
            </p>
          ) : (
            <p className="vin__number__error">
              Für das Fahrzeug <span>{input}</span> konnte keine VIN in der
              Datenbank gefunden werden.
              <br />
              <br />
              Dies bedeutet, dass die vRB für dieses Fahrzeug nicht bezahlt
              wurde
            </p>
          );

          setOutput(outputContent);
        });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <header
        className="header"
        onClick={() => {
          setOutput("");
          setInputValue("");
        }}
      >
        <h1 className="header__heading">VIN Check</h1>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="vin__number">
          <input
            type="text"
            name="input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={{
              borderColor:
                inputValue !== "" && inputValue.length !== 17
                  ? "#ffa900"
                  : "initial",
            }}
          />
          <button className="button" type="submit">
            PRÜFEN
          </button>
        </div>
      </form>
      {output}
    </Container>
  );
}

export default VINNumber;
