import React from "react";

function Loader() {
  return (
    <div className="loader-default__wrapper">
      <div className="loader-default">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loader;
